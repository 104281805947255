import * as t from 'io-ts';
import optionalToUndefined from '../t/optionalToUndefined';

const YotiSession = t.type({
    sessionId: t.string,
    clientSessionToken: t.string,
});

export type YotiSession = t.TypeOf<typeof YotiSession>;

const YotiDetailsResponse = t.type({
    name: t.string,
    value: t.string,
});

export type YotiDetailsResponse = t.TypeOf<typeof YotiDetailsResponse>;

const YotiRecommendationResponse = t.type({
    value: optionalToUndefined(t.string),
    reason: optionalToUndefined(t.string),
    recoverySuggestion: optionalToUndefined(t.string),
});

export type YotiRecommendationResponse = t.TypeOf<typeof YotiRecommendationResponse>;

const YotiBreakdownResponse = t.type({
    subCheck: optionalToUndefined(t.string),
    result: optionalToUndefined(t.string),
    details: optionalToUndefined(t.array(YotiDetailsResponse)),
});

export type YotiBreakdownResponse = t.TypeOf<typeof YotiBreakdownResponse>;

const YotiReportResponse = t.type({
    recommendation: YotiRecommendationResponse,
    breakdown: t.array(YotiBreakdownResponse),
});

export type YotiReportResponse = t.TypeOf<typeof YotiReportResponse>;

const YotiCheck = t.type({
    id: t.string,
    type: t.string,
    state: t.string,
    resourcesUsed: t.array(t.string),
    report: YotiReportResponse,
    created: t.string,
    lastUpdated: t.string,
});

export type YotiCheck = t.TypeOf<typeof YotiCheck>;

const YotiIdDocumentsResourceResponse = t.type({
    id: optionalToUndefined(t.string),
    documentType: optionalToUndefined(t.string),
    issuingCountry: optionalToUndefined(t.string),
    photoMediaId: optionalToUndefined(t.string),
    pagesMediaId: optionalToUndefined(t.array(t.string)),
    fieldsMediaId: optionalToUndefined(t.string),
});

export type YotiIdDocumentsResourceResponse = t.TypeOf<typeof YotiIdDocumentsResourceResponse>;

const YotiDocumentText = t.partial({
    documentId: t.string,
    documentText: t.string,
});

export type YotiDocumentText = t.TypeOf<typeof YotiDocumentText>;

const YotiImage = t.partial({
    documentId: t.string,
    content: t.string,
    mimeType: t.string,
});

export type YotiImage = t.TypeOf<typeof YotiImage>;

const YotiResourceContainer = t.type({
    idDocuments: t.array(YotiIdDocumentsResourceResponse),
    textExtractions: t.array(YotiDocumentText),
    photos: t.array(YotiImage),
    pages: t.array(YotiImage),
});

export type YotiResourceContainer = t.TypeOf<typeof YotiResourceContainer>;

const YotiResult = t.type({
    sessionId: t.string,
    userTrackingId: t.string,
    state: t.string,
    checks: t.array(YotiCheck),
    resources: YotiResourceContainer,
});

export type YotiResult = t.TypeOf<typeof YotiResult>;
