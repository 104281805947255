import React from 'react';
import { YotiDocumentText, YotiIdDocumentsResourceResponse, YotiImage } from 'proxyaddress-common/types';
import Detail from '../../bits/Details/Detail';
import { formatScreamingSnakeCase } from '../../../utils/helpers';
import Caption from '../../bits/Caption/Caption';
import styled from 'styled-components';
import { DetailSection } from './YotiCheck';

interface YotiDocumentDetailProps {
    textExtractions: YotiDocumentText[];
    documents: YotiIdDocumentsResourceResponse[];
    photos: YotiImage[];
}

const PhotoWrapper = styled.div`
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;

    > img {
        flex-basis: 40%;
        flex-grow: 0;
        flex-shrink: 0;
        max-width: 40%;
    }
`;

const displayFields = ['full_name', 'date_of_birth', 'place_of_birth', 'nationality', 'gender'];

const YotiDocumentDetail = ({ textExtractions, documents, photos }: YotiDocumentDetailProps) => (
    <>
        {documents.map((document) => {
            const documentTextExtractions = textExtractions.filter((te) => te.documentId === document.id);

            return (
                <div key={document.id}>
                    <DetailSection>
                        <Detail
                            caption="Document type:"
                            detail={formatScreamingSnakeCase(document.documentType || '-')}
                            inline
                        />
                        <Detail caption="Issuing country:" detail={document.issuingCountry} inline />
                        {textExtractions && (
                            <>
                                {documentTextExtractions.map((textExtraction) => {
                                    if (!textExtraction.documentText) {
                                        return null;
                                    }

                                    const parsedText = JSON.parse(textExtraction.documentText);

                                    const filteredText = Object.keys(parsedText)
                                        .filter((key) => displayFields.includes(key))
                                        .reduce((obj: { [key: string]: any }, key) => {
                                            obj[key] = parsedText[key];
                                            return obj;
                                        }, {});

                                    return Object.entries(filteredText).map(([key, value]) => (
                                        <Detail
                                            key={key}
                                            caption={`${formatScreamingSnakeCase(key)}:`}
                                            detail={value}
                                            inline
                                        />
                                    ));
                                })}
                            </>
                        )}
                    </DetailSection>
                    {photos && photos.length > 0 && (
                        <DetailSection>
                            <Caption>Photos:</Caption>
                            <PhotoWrapper>
                                {photos.map((photo, index) => (
                                    <img
                                        key={`${photo.documentId}_${index}`}
                                        src={photo.content}
                                        alt={`${photo.documentId}_${index}`}
                                    />
                                ))}
                            </PhotoWrapper>
                        </DetailSection>
                    )}
                </div>
            );
        })}
    </>
);

export default YotiDocumentDetail;
