import React from 'react';
import styled from 'styled-components';
import { YotiCheck as IYotiCheck, YotiResult } from 'proxyaddress-common/types';
import { formatScreamingSnakeCase } from '../../../utils/helpers';
import { charlie, delta } from '../../../theme/sizing';
import Detail from '../../bits/Details/Detail';
import YotiBreakdown from './YotiBreakdown';
import { Icon } from '../../bits/Icons';
import { getRecommendationColor, getRecommendationIcon } from '../../../utils/yoti';
import { shade500 } from '../../../theme/colours';
import Caption from '../../bits/Caption/Caption';
import { formatDetailsDate } from '../../../utils/staffDashboard';
import YotiDocumentDetail from './YotiDocumentDetail';

interface YotiCheckProps {
    check: IYotiCheck;
    session: YotiResult;
}

const Details = styled.details`
    background-color: white;
    margin-bottom: 0.5rem;
    padding: ${delta};
`;

const Summary = styled.summary`
    align-items: center;
    cursor: pointer;
    display: flex;
`;

const DetailInner = styled.div`
    margin-top: 1rem;
`;

export const DetailSection = styled.div`
    + div {
        margin-top: 1rem;
    }
`;

interface SummaryIconProps {
    color: string;
}

export const YotiCheckIcon = styled(Icon)<SummaryIconProps>`
    fill: ${(props) => props.color};
    margin-right: ${charlie};
`;

const SummaryTitle = styled.h3`
    font-weight: bold;
`;
const SummaryDate = styled.span`
    color: ${shade500};
    font-size: 0.8rem;
    margin-left: auto;
`;

const Meta = styled.div`
    color: ${shade500};
    font-size: 0.7rem;
    margin-top: 1rem;
    text-align: right;
`;

const YotiCheck = ({ check, session }: YotiCheckProps) => {
    const breakdown = check.report.breakdown;

    const recommendation = check.report.recommendation.value
        ? formatScreamingSnakeCase(check.report.recommendation.value)
        : 'No recommendation';
    const recommendationLabel = check.report.recommendation.reason
        ? `${recommendation} - ${formatScreamingSnakeCase(check.report.recommendation.reason, true)}`
        : recommendation;

    const checkResources = check.resourcesUsed;
    const sessionTextExtractions = session.resources.textExtractions;
    const checkExtractions = sessionTextExtractions?.filter(
        (textExtraction) => textExtraction.documentId && checkResources.includes(textExtraction.documentId),
    );
    const checkDocuments = session.resources.idDocuments?.filter(
        (document) => document.id && checkResources.includes(document.id),
    );
    const sessionPhotos = session.resources.photos;
    const checkPhotos = sessionPhotos?.filter((photo) => photo.documentId && checkResources.includes(photo.documentId));
    const sessionPages = session.resources.pages;
    const checkPages = sessionPages?.filter((page) => page.documentId && checkResources.includes(page.documentId));

    return (
        <Details>
            <Summary>
                {check.report.recommendation.value && (
                    <YotiCheckIcon
                        icon={getRecommendationIcon(check.report.recommendation.value)}
                        color={getRecommendationColor(check.report.recommendation.value)}
                    />
                )}
                <SummaryTitle></SummaryTitle>
                {formatScreamingSnakeCase(check.type)}
                <SummaryDate>{formatDetailsDate(check.created)}</SummaryDate>
            </Summary>
            <DetailInner>
                <DetailSection>
                    <Detail caption="Recommendation:" detail={recommendationLabel} inline />
                    {check.report.recommendation.recoverySuggestion && (
                        <Detail caption="Suggestion:" detail={check.report.recommendation.recoverySuggestion} />
                    )}
                </DetailSection>
                {breakdown?.length > 0 && (
                    <DetailSection>
                        <Caption>Breakdown:</Caption>
                        {breakdown.map((breakdownItem) => (
                            <YotiBreakdown breakdown={breakdownItem} key={breakdownItem.subCheck} />
                        ))}
                    </DetailSection>
                )}
                {(checkDocuments || checkExtractions || checkPhotos || checkPages) && (
                    <DetailSection>
                        <YotiDocumentDetail
                            textExtractions={checkExtractions}
                            documents={checkDocuments}
                            photos={[...checkPages, ...checkPhotos]}
                        />
                    </DetailSection>
                )}
            </DetailInner>
            <Meta>Yoti Session ID: {session.sessionId}</Meta>
        </Details>
    );
};

export default YotiCheck;
